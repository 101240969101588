body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, sans-serif;
}

/* Font sizes */
.fs8{font-size: 10px !important;}
.fs10{font-size: 10px !important;}
.fs11{font-size: 11px !important;}
.fs12{font-size: 12px !important;}
.fs13{font-size: 13px !important;}
.fs14{font-size: 14px !important;}
.fs15{font-size: 15px !important;}
.fs16{font-size: 16px !important;}
.fs18{font-size: 18px !important;}                                              
.fs20{font-size: 20px !important;}
.fs22{font-size: 22px !important;}
.fs24{font-size: 24px !important;}
.fs26{font-size: 26px !important;}
.fs28{font-size: 28px !important;}
.fs30{font-size: 30px !important;}
.fs32{font-size: 32px !important;}
.fs48{font-size: 48px !important;}

/* Font Weights */
.fw200 {
  font-weight: 200 !important;
}
.fw300 {
  font-weight: 300 !important;
}
.fw400 {
  font-weight: 400 !important;
}
.fw500 {
  font-weight: 500 !important;
}
.fw600 {
  font-weight: 600 !important;
}
.fw700 {
  font-weight: 700 !important;
}

/* Border Radius */
.br4 {
  border-radius: 4px !important;
}
.br8 {
  border-radius: 8px !important;
}
.br20 {
  border-radius: 20px !important;
}

/* Background Colors */
.bg-grey1 {
  background: #f4f5f7 !important;
}
.bg-grey2 {
  background: #b6b6b6 !important;
}

/* Text Colors */
.grey1{color: #9BADC1 !important;}
.blue1{color: #2977d2 !important;}
.blue2{color: #123e6e !important;}
.blue3{color: #043365 !important;} /* INCEDO BLUE */
.green1{color: #00875A !important;}
.green2{color: #178e17 !important;}
.red1{color: #e21111 !important;}

.cursor-pointer {
  cursor: pointer;
}

.bold500 {
  font-weight: 500 !important;
}
.mb35 {
  margin-bottom: 35px !important;
}
.w300 {
  width: 300px;
}

.basic-textarea {
  padding: 12px;
  border-radius: 4px;
  color: #24292f;
  background: #fff;
  border: 1px solid #d0d7de;
  box-shadow: 0px 2px 2px #f6f8fa;

  &:hover {
    border-color: #24292f;
  }

  &:focus {
    border-color: #3399ff;
  }

  &:focus-visible {
    outline: 0;
  }
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
/* Gaps in pixels */
.g1 {
  gap: 1px;
}
.g2 {
  gap: 2px;
}
.g3 {
  gap: 3px;
}
.g4 {
  gap: 4px;
}
.g5 {
  gap: 5px;
}
.g9 {
  gap: 9px;
}

/* Colors */
.grey-1 {
  color: #bdbdbd;
}

.grey-2 {
  color: rgba(9, 10, 11, 0.53);
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.green-text {
  color: #4caf50 !important;
}
.amber-text {
  color: #ffc107 !important;
}
.red-text {
  color: #f44336 !important;
}

.home-feature-box .fa {
  font-size: 6rem;
}

.home-feature-box span {
  display: block;
  color: #111;
  font-weight: bold;
  margin-top: 1.5rem;
}

.example-components-list li > a {
  color: #495057;
}

.example-components-list li:last-child > a {
  border-bottom: 0;
}

.example-components-list li > a .fa {
  color: rgba(0, 0, 0, 0.35);
  float: right;
}
.condensed-table > thead > tr > th,
.condensed-table > tbody > tr > th,
.condensed-table > tfoot > tr > th,
.condensed-table > thead > tr > td,
.condensed-table > tbody > tr > td,
.condensed-table > tfoot > tr > td {
  padding: 5px;
}
.row-2-col-2 {
  box-sizing: border-box;
  display: grid;
  height: 100%;
  margin-top: 0;
  /* grid-template-rows: 13% repeat(3, 40%); */
  grid-template-columns: 8% repeat(2, 40%);
  row-gap: 2%;
  grid-column-gap: 1%;
  /* cursor: pointer; */
  align-items: center;
  justify-items: center;
}

.segment-col {
  font-size: 0.8rem;
  width: 100%;
  /* font-weight: 600; */
}

.segment-row {
  font-size: 0.8rem;
  width: 100%;
  height: 2.5rem;
  text-align: center;
  justify-items: center;
  /* font-weight: 600; */

  /* & p {
    text-align: center;
  }*/
}

.segment-button {
  font-size: 0.8rem;
  height: 2.5rem;
  width: 100%;
}

.segment-header-button {
  font-size: 0.8rem;
  height: 2rem;
  width: 100%;
}

.no-border {
  border: none;
}

.segment-p {
  margin: 0px;
}

.segment-q {
  margin: 0px;
  padding: 0.8rem 0;
}

.segment-data11 {
  background-color: #8faadc;
  color: #ffffff;
}

.segment-data21 {
  background-color: #cfdfea;
  color: #111;
}

.segment-data22 {
  background-color: #cfdfea;
  color: #ffffff;
}

.segment-data23 {
  background-color: #f2f2f2;
  color: #111;
}

.segment-data31 {
  background-color: #f20002;
}

.segment-data32 {
  background-color: #ed7c7f;
}

.segment-data33 {
  background-color: #f1c5c9;
}

.navbar.navbar-dark .navbar-nav .nav-item .nav-link:hover {
  color: #2a2a2a !important;
  background-color: #f8f9fa !important;
}

h4 {
  font-weight: bold;
}

/* li .ant-menu-item {
  padding-left: 33px !important;
  color: #111111 !important;
} */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d4d6da;
  border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9adab;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a9adab;
}

.ant-menu-inline-collapsed {
  /* width: 3rem !important; */
  /* LC-489 */
  width: 3rem !important;
}

#side-title a {
  list-style: none;
}

#side-title a:hover {
  color: #111 !important;
}

.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  #sidemenu-title {
  /* padding-left: 0px !important; */
  border-left: '2px solid red';
  padding-left: '0px';
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 2rem !important;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  height: 1.6rem !important;
  font-weight: 500;
  line-height: 1.6rem !important;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 2rem;
}

.sidebar-link-dark {
  color: #fff;
}

.sidebar-link-light {
  color: #111;
}

/* .ant-menu-item a:hover {
  color: blue !important;
} */

.ant-tooltip-inner,
.ant-tooltip-arrow-content {
  background-color: #fff !important;
}

.ant-tooltip-inner a {
  color: rgba(0, 0, 0, 0.85) !important;
}

/** Adherence page Funnel Chart*/
.funnel-text-label {
  padding: 5% 0.5ch;
  color: #fff;
}

.radio-style {
  display: block !important;
  height: 32px !important;
  line-height: 30px !important;
  padding-left: 20px !important;
  /* margin-top:1px !important; */
  font-size: 1rem !important;
  width: 100% !important;
  font-weight: 600;
}

/* for Admin Configuration close accordion */
.ant-collapse-content-inactive {
  display: none;
}

.ant-collapse-content-active {
  display: block;
}

.md-form {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Profile Card */
.ant-popover-inner-content {
  padding: 0px !important;
}

.profile-icon {
  background: #043365;
  min-width: 35px;
  min-height: 35px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50% !important;
  display: flex !important;
}

.ant-popover-arrow {
  border-style: none !important;
}

/*Login Drop down */
select option.selected {
  background-color: green;
}

select option:checked:after {
  content: attr(title);
  background: #666;
  color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  border: none;
}

/*Menu configuration Drop down */
.menuConfSelect .ant-select-selection .ant-select-selection__rendered {
  line-height: 19px;
}

.menuConfSelect .ant-select-selection--single {
  height: 20px;
  border: 0;
}

#kpi-config-input {
  padding: 0px !important;
  font-size: 14px;
  margin: 0px;
}

.rbt > .form-control {
  padding: 0px !important;
  border: 0;
  font-size: 0.9rem;
  height: auto;
  /* max-height: 1rem; */
  /* max-width: max-content; */
  /* width: 100% !important; */
  /* max-height: 1.4rem; */
  border-radius: 0;
}

.focus {
  border-color: #fff !important;
}

#basic-example {
  /* transform: translate3d(2px, 27px, 0px) !important; */
  max-height: 400px !important;
}

.rbt-token-removeable {
  cursor: pointer;
  padding-right: 0px !important;
}

.rbt-token .rbt-token-active {
  background-color: #007bff;
  color: #fff;
  outline: none;
  text-decoration: none;
}

.rbt-token {
  background-color: #fff !important;
  border: 0;
  border-radius: 0.25rem;
  /* color: #111 !important; */
  display: inline-block;
  line-height: 1em;
  margin: 1px 3px 2px 0;
  padding: 4px 7px;
  position: relative;
}

.rbt-token .rbt-token-remove-button {
  display: none;
  bottom: 0;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 3px 7px;
  position: absolute;
  right: 0;
  text-shadow: none;
  top: -2px;
}

.show {
  min-width: 15rem !important;
  font-size: medium;
  margin-top: 0.5rem;
}

/* #kpi-visual .ant-select-selection{
  border:0px !important;
  border-bottom:1px solid #d9d9d9 !important; 
  font-size: small;
} */
#kpi-menu-input .ant-select-selection {
  border: 0px !important;
  font-weight: 400;
  background-color: #59698d;
  color: #fff;
}

#kpi-menu-input > div > span {
  color: #fff;
}

#kpi-menu-input > div > div > div {
  color: #fff;
}

/* #kpi-visual-input {
  transform: translate3d(2px, 27px, 0px) !important;
} */

#kpi-elment-input .ant-select-selection {
  border: 0px !important;
  background-color: #365792;
  /*#59698d;#DBE5F4*/
  color: #ffffff;
}

#kpi-modal-input .ant-select-selection {
  border: 0px !important;
  /* background-color: #365792;  */
  /* color: #ffffff; */
  margin-bottom: 1rem;
}

#kpi-modal-input .ant-select-selection__rendered {
  margin-left: 0px;
  font-weight: 400;
}

.kpiCard .md-form {
  margin: 5px;
}

.kpiCard input {
  border-radius: 0;
}

.kpiCard .rbt {
  margin-left: 5px;
}

/* Kpi cards hover effect */
.cardHover {
  top: 0;
  left: 0;
  width: 344px;
  min-height: 182px;
  box-shadow:
    0px 0px 3px #cdd6e0,
    0px 2px 8px #cdd6e0;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

.cardHoverText {
  background: #e6ebf0;
  color: #365c84;
  border-radius: 10px;
  font-weight: 500;
  text-transform: capitalize;
}

/*Menu configuration Drop down */
.KPIConfSelect .ant-select-selection .ant-select-selection__rendered {
  line-height: 1.8rem;
  margin-left: 5px;
  width: 85px;
  /* font-size: 0.65rem; */
}

.KPIConfSelect .ant-select-selection--single {
  height: 1.3rem;
  border: 0;
}

.KPIConfSelect .ant-select-arrow {
  margin-top: -2px;
}

.no-outline:focus {
  outline: none;
}

.no-outline .form-control .rbt-input-wrapper .rbt-token {
  padding: 4px 1px;
}

.rbt .form-control .rbt-input-wrapper .rbt-token {
  padding: 4px 1px;
}

.ant-statistic .ant-statistic-title {
  font-size: 12px;
  color: #000000;
}

.ant-statistic-content
  .ant-statistic-content-value
  .ant-statistic-content-value-decimal {
  font-size: 30px;
}

.ant-layout-content .tabCustomization {
  border: 1px solid #e8e8e8;
  margin-top: 20px;
}

.tabCustomization .ant-tabs-bar {
  margin: 0 0 1rem 0;
}

.tabWidth .ant-tabs-top-bar .ant-tabs-tab {
  width: 22.8%;
  text-align: center;
}

.tabWidthMM .ant-tabs-top-bar .ant-tabs-tab {
  width: 26.2%;
  text-align: center;
}

.tabCustomization .ant-tabs-top-bar .ant-tabs-tab {
  margin: 0 5px 0 0;
  background: rgb(97, 133, 199);
  color: #ffffff;
  font-weight: bold;
  width: 230px;
}

.tabCustomization .ant-tabs-nav .ant-tabs-tab-active {
  color: #fff;
}

.tabCustomization .ant-tabs-top-bar .ant-tabs-tab-active {
  background: rgb(32, 56, 100);
}

#clinical-select .ant-select-selection {
  border: 0px !important;
  border-bottom: 1px solid rgb(206, 212, 218) !important;
  margin-top: 0.7rem;
}

.arrow_box {
  position: relative;
  background: #dddcdc;
  border: 1px solid #b4b4b4;
  border: 5px;
  padding: 3px;
}

#legend li {
  display: table-row;
}

#legend .percent {
  height: 12px;
  width: 12px;
  text-shadow: 0 0 1px black;
  padding: 0 10px;
  border-radius: 2px;
  margin-right: 5px;
}

.affix {
  top: 0;
  width: 100%;
  z-index: 9999 !important;
}

.KPImidarea {
  display: grid;
  grid-template-columns: auto min-content;
  height: 100%;
}

.KPImain {
  grid-column: 1/2;
  overflow-y: scroll;
  overflow-x: hidden;
}

.KPIside {
  grid-column: 2/3;
}

/**************** New Design *************/
.kpi--heading {
  font-size: 1.5rem;
}

.mw-none {
  min-width: 0px !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.zindex-2 {
  z-index: 2;
}

/**************** START *************/

.KPImain .heading-title {
  font-size: 2.13rem;
  color: #1e4564;
}

/* #treeWrapper .tree-node{
  height: auto !important;
  position: relative;
  border-radius: 0.5rem !important;
}
#treeWrapper .tree-node .node-status{
  height: 14px !important;
}
 */
#treeWrapper .tree-node p {
  margin-bottom: 0;
}

.custom-tooltip {
  display: none;
  position: absolute;
  width: max-content;
  top: -38px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 15px;
  border-radius: 2px;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
  background: #fff;
  box-shadow: -2px 2px 7px 3px rgba(0, 0, 0, 0.1);
  transition: all linear 0.2s;
}

.node-title:hover > .custom-tooltip {
  display: block;
}

/**************** END *************/

.multi-select {
  --rmsc-main: #4285f4 !important;
  --rmsc-hover: #f1f3f5 !important;
  --rmsc-selected: #e2e6ea !important;
  --rmsc-border: #ccc !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #fff !important;
  --rmsc-p: 10px !important;
  /* Spacing */
  --rmsc-radius: 4px !important;
  /* Radius */
  --rmsc-h: 32px !important;
  /* Height */
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.3rem;
  grid-auto-rows: auto;
}

/* revamp */
.KPIDrawer.ant-drawer-bottom {
  width: calc(100% - 56px);
  right: 0;
  left: auto;
  z-index: 999;
  transition: all 0.2s !important;
}

.KPIDrawer.ant-drawer-bottom.collapsed {
  width: calc(100% - 221px);
}

.KPIDrawer .ant-drawer-content-wrapper {
  /* -webkit-box-shadow: 1px 0px 8px #5f76bc !important;
  box-shadow: 1px 0px 8px #5f76bc !important; */
  box-shadow: none !important;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
}

.KPIDrawer .ant-drawer-content-wrapper::after {
  content: '';
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 100%;
  height: 4px;
  background: linear-gradient(
    rgba(9, 30, 66, 0) 0px,
    rgba(9, 30, 66, 0.08) 1px,
    rgba(9, 30, 66, 0.13) 1px,
    rgba(9, 30, 66, 0.13) 4px
  );
}

.KPIDrawer .ant-drawer-content {
  overflow: inherit;
}
.theme-dark.KPIDrawer .ant-drawer-content {
  background: #121212;
}

.theme-dark.KPIDrawer .ant-drawer-content-wrapper::after {
  content: '';
  background: linear-gradient(
    rgba(89, 138, 223, 0.05) 0px,
    rgba(89, 138, 223, 0.08) 1px,
    rgba(89, 138, 223, 0.1) 1px,
    rgba(89, 138, 223, 0.3) 4px
  );
}

.KPIDrawer .drawer-nav {
  position: absolute;
  top: -48px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1111;
  overflow: hidden;
  padding: 10px 10px 0;
}

.KPIDrawer .drawer-nav .drawer-nav-icons {
  box-shadow: 0px 0px 8px #5f76bc;
  background: #fff;
  border-radius: 12px 12px 0 0;
  text-align: center;
  padding: 3px 12px;
}

.theme-dark.KPIDrawer .drawer-nav .drawer-nav-icons {
  box-shadow: 0px 0px 8px rgba(89, 138, 223, 0.6);
  background: #121212;
}

.theme-dark.KPIDrawer .drawer-nav .drawer-nav-icons .text-dark {
  color: #fff !important;
}

.table-theme-bordered .ant-table table {
  border: 1px solid #cdd6e0;
  border-radius: 8px !important;
}

.table-theme-bordered .ant-table table td {
  border-bottom: 1px solid #cdd6e0 !important;
  color: #091e42;
  font-weight: 500;
}

.table-theme-bordered .ant-table table th {
  border-bottom: 1px solid #cdd6e0 !important;
  background: #d4ebf8;
  color: #091e42;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: normal;
}

.theme-dark .table-theme-bordered .ant-table table th {
  background: #121212;
  color: #fff;
}

.theme-dark .table-theme-bordered .ant-table table td {
  background: #121212;
  color: #fff;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #fafafa;
  color: #091e42;
}

.theme-dark .ant-table-tbody > tr.ant-table-row:hover > td,
.theme-dark .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #242424;
  color: #fff;
  border-top: 2px solid #cdd6e0 !important;
  border-bottom: 2px solid #cdd6e0 !important;
}

.table-theme-bordered .ant-table table th:first-child {
  border-top-left-radius: 8px !important;
}

.table-theme-bordered .ant-table table th:last-child {
  border-top-right-radius: 8px !important;
}

.table-theme-bordered .ant-table table tr:last-child td,
.table-theme-bordered .ant-table table tr.last-child th {
  border-bottom: none !important;
}

/* Table Scrollable */
.table-theme-bordered-scrollable .ant-table {
  border: 1px solid #cdd6e0 !important;
  border-radius: 8px !important;
}

/* Tab */
.tab-theme-1 .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #000;
}

.tab-theme-1 .css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}

.action-modal .ant-modal-title {
  color: #043365;
  font-size: 24px;
}

.action-card {
  position: relative;
  min-height: 140px;
  min-width: 416px;
  max-width: 416px;
  width: 100%;
  padding: 12px;
  border-radius: 8px !important;
  box-shadow:
    0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31) !important;
  transition: all linear 0.2s !important;
}

.action-card-skeleton {
  border-radius: 8px !important;
  padding: 12px;
}

.action-card-header {
  position: relative;
  z-index: 1;
}

.action-card.expanded {
  min-height: 280px;
  border: 1px solid #043365;
}

.action-card.expanded:after {
  content: '';
  width: 100%;
  height: 55px;
  background: #d4ebf8;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.monitoring-card {
  border: 1px solid #cdd6e0;
  padding: 12px;
  margin: 12px;
  border-radius: 8px !important;
  min-height: 130px;
  max-width: 350px;
  min-width: 350px;
  width: 100%;
  -webkit-box-shadow: 0px 1px 4px rgba(9, 30, 66, 0.25) !important;
  box-shadow: 0px 1px 4px rgba(9, 30, 66, 0.25) !important;
}

/* Asset Component */
.salute-section {
  padding: 2rem;
}

.salutation {
  font-weight: 300;
  text-transform: capitalize;
}

.salutation-description {
  border-radius: 0.5rem;
  background: #043365;
  color: #fff;
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.gotit-btn {
  background-color: #fff;
  padding: 8px 48px;
  color: #000;
  border-radius: 4px;
  border: none;
}

.sal-image {
  max-width: 422px;
}

.custom-container {
  padding: 2rem 2rem 3rem 2rem;
}

.asset--card {
  border-radius: 5px !important;
  min-width: 150px;
}

.asset--card:hover p,
.asset--card:hover span,
.asset--card:hover {
  background: #043365;
  color: white !important;
}

.asset-card-active,
.asset-card-active p,
.asset-card-active span {
  background: #043365 !important;
  color: white !important;
}

.asset-link span {
  color: #7f7f7f;
}

.carousel {
  overflow: hidden;
  position: relative;
}

.slider {
  position: relative;
  width: 100%;
  overflow-x: scroll;
}

.slider::-webkit-scrollbar {
  display: none;
}

.slide-left {
  left: 0;
  box-shadow: 0 10px 20px 20px #fbfbfc;
}

.slide-right {
  right: 0;
  box-shadow: 10px 0 20px 20px #fbfbfc;
}

.slide-left,
.slide-right {
  align-items: center;
  background: #fbfbfc;
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}

.circle-left {
  left: 20px;
}

.circle-right {
  right: 20px;
}

.circle-left,
.circle-right {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.circle-left span,
.circle-right span {
  background-color: #fff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #8993a4;
  cursor: pointer;
}

.wrapper {
  display: flex;
  padding: 3px;
  transition: transform 0.3s;
}

/******** Parameter management ****************/
.search-input {
  background: transparent;
}

.bordered-2 {
  border: 2px solid #ccc;
}

.img-200 {
  max-width: 250px;
  height: auto;
}

.select-mui div.MuiOutlinedInput-input {
  padding: 10px 14px !important;
}

.label-mui {
  left: 15px !important;
  top: -12px !important;
  position: absolute !important;
}

/******** Utility classes ****************/
.h-3 {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 1rem;
}

.h-4 {
  font-size: 16px;
  font-weight: 500;
}

.text-d-none {
  text-decoration: none;
  color: black;
}

.no-outline:focus {
  outline: none;
}

.config-tabs {
  color: #9badc1;
  border-bottom: 1px solid #c4ccd6;
}

/******** KPI Tree ****************/
.tree-container {
  padding-bottom: 2rem;
}

.domain-card {
  margin-right: 2rem;
  border-radius: 6px;
  overflow: hidden;
  box-shadow:
    0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  max-width: 344px;
  width: 100%;
}

.domain-card-skeleton {
  margin-right: 2rem;
  margin-bottom: 48px;
  border-radius: 6px;
}

.tree-image {
  min-width: 344px;
  width: 100%;
  height: 194px;
  background-color: #ebecf0;
}

.tree-icon {
  height: 17px;
  width: 17px;
  color: #7f7f7f;
  padding: 4px 30px 0 0;
}

.tree-metrics {
  padding: 18px;
  min-height: 118px;
}

.metrics-values {
  font-size: 12px;
  max-height: 60px;
  color: #7a869a;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.metrics-values div {
  min-width: 50%;
}

/******** Configuration and Settings ****************/
.param-table {
  width: 60%;
}

.edit-box {
  width: 38%;
}

.param-info {
  padding-bottom: 32px;
}

.active-cell {
  background-color: #d7ebf6;
  color: #003087;
}

/******** KPI Right Drawer ****************/
.cohort-tbl-small .ant-card-head-wrapper .ant-card-head-title {
  padding: 1px 0;
}

.cohort-tbl-small .ant-card .ant-card-head {
  min-height: 0;
  padding-left: 3px;
}

.cohort-tbl-small .ant-table-tbody > tr > td {
  padding: 0;
  color: #111;
  color: rgba(18, 18, 18, 0.87);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 2rem !important;
}

.cohort-tbl-small .ant-table-thead th {
  padding: 0;
}

#side-table > tbody > tr > td {
  white-space: nowrap;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  padding: 5px;
  font-size: 0.7rem;
  color: rgba(18, 18, 18, 0.87);
  letter-spacing: 0.4px;
  line-height: 0.7rem;
  font-weight: 500;
  max-width: 10rem;
}

#side-table > thead > tr > th {
  white-space: nowrap;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  padding: 3px;
  font-size: 1rem;
  color: rgba(18, 18, 18, 0.87);
  letter-spacing: 0.15px;
  line-height: 1.4rem;
  font-weight: 500;
}

.side-select {
  font-size: 0.8rem !important;
  color: rgba(18, 18, 18, 0.87) !important;
  letter-spacing: 0.1px !important;
  line-height: 0.8rem !important;
  font-weight: 500 !important;
  text-align: start;
  padding-top: 4px;
}

/* Cohort Table data - bold */
.Hello--hide-expand > td {
  font-weight: bold;
}

/* header*/
.header-menu {
  text-align: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}

/* Card Icon */
.node-bottom {
  border-radius: 0.2rem;
  box-shadow: 0 5px 10px 0 rgba(34, 36, 38, 0.45);
  position: absolute;
  bottom: -1.6rem;
  background: #111;
  color: #fff;
  padding: 0.2rem 0.4rem;
  width: 1.6rem;
  height: 1.6rem;
}

.home-node-attr-1 {
  font-size: 0.7rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-node-attr-2 {
  font-size: 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sec {
  position: relative;
  right: -20px;
  top: -22px;
  background-color: #d3d3d3;
  border-radius: 6px;
  font-weight: 700;
  color: #000;
  padding: 1px 7px;
}

.counter.counter-lg {
  top: -3rem !important;
}

.ant-drawer .ant-tree-switcher .ant-tree-switcher-icon {
  font-size: 14px;
}

.tabs-panel-default button:focus {
  outline: none !important;
}

.tabs-panel-default .MuiTabs-indicator {
  height: 4px;
  border-radius: 10px;
  background-color: #4285f4;
}

.tabs-panel-default .MuiTab-wrapper {
  font-size: 1rem;
  font-weight: normal;
}

.tabs-panel-default .Mui-selected .MuiTab-wrapper {
  font-weight: bold;
  color: #4285f4;
}

.MuiTabs-vertical .MuiTab-wrapper {
  display: block;
}

.kpi-list-default .node-bottom {
  display: none;
}

.pills {
  height: 36px;
  width: 80px;
  text-align: center;
  line-height: 34px;
  color: #000;
  border-radius: 80px;
  background: rgba(204, 204, 204, 0.3);
  border: 1px solid #cccccc;
  cursor: pointer;
}

.calender-wrapper-default {
  width: 279px;
  height: 310px;
}

.calender-wrapper-default .ant-picker-dropdown .ant-picker-panel-container {
  box-shadow: none;
  border: 1px solid #f0f0f0;
}

.calender-wrapper-default
  .ant-picker-panel-container
  .ant-picker-header
  button
  :not(.ant-picker-header-view button) {
  display: none;
  pointer-events: none;
}

.bordered-8 span,
.bordered-8 div {
  border-radius: 2px;
}

/* Cohort ops preview css start */
.cohort-action-popover {
  border-radius: 5px;
}

.cohort-action-more {
  padding: 7px 10px;
  width: 100px;
  cursor: pointer;
  margin-top: -12px;
  color: black;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
}

.preview-info-icon .ant-popover-content {
  margin-left: -8px !important;
}

.preview-info-icon .ant-popover-inner {
  border-radius: 5px !important;
  width: 70% !important;
}

.preview-info-icon .ant-popover-inner-content {
  padding: 3px !important;
}

.MuiTablePagination-toolbar {
  align-items: baseline !important;
}

.modal-modification > .ant-modal-content,
.modal-modification > .ant-modal-content > .ant-modal-header {
  border-radius: 12px;
}

.export-request-modal {
  max-height: 460px;
  padding: 0px 42px !important;
  overflow-y: auto;
}

.ant-modal-close-x {
  position: absolute;
  right: 18px;
  width: initial !important;
}

.preview-link {
  margin-left: 5px;
  cursor: pointer;
  color: #0a0ae8e3;
}

.sample-export {
  height: 43px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #043365 !important;
  border-radius: 4px !important;
  border-color: #043365 !important;
}

.full-export {
  height: 43px !important;
  background: #043365 !important;
  border-radius: 4px !important;
  border-color: #043365 !important;
  color: white !important;
}

.maximise-cohort-data {
  background: none;
  border: none;
  float: right;
  position: absolute;
  top: 18px;
  right: 40px;
  color: gray;
}

.cancel-full-report-request {
  height: 43px !important;
  border: none !important;
  font-weight: 500 !important;
  color: #4b4e515c;
}

.full-report-label {
  width: 72px;
  font-weight: 500;
  align-self: center;
  margin-bottom: 0px;
}

.full-report-category,
.full-report-fields,
.full-report-format,
.full-report-cohorts {
  height: 40px;
  font-size: 14px !important;
  font-weight: 400;
  width: 158px;
}

.preview-button {
  display: inline-flex;
  border: none;
  background-color: #ffffff;
  padding: 0px;
}

.preview-button > span {
  background-color: #ffffff;
  margin-top: 3px;
  color: #031af4;
}

.preview-button:hover {
  color: black;
}

.disabled-cohort-preview {
  cursor: no-drop;
  opacity: 0.5;
  transform-style: preserve-3d;
}

.full-report-row {
  flex-direction: row !important;
}

.mr-2-rem {
  margin-right: 2.1rem;
}

.full-report-row img {
  margin-left: 10px !important;
}

.field-preview {
  display: flex;
  align-self: center;
  font-weight: 500;
  color: #365c84;
  cursor: pointer;
  margin-left: 10px;
}

.selectedChip {
  background-color: #ebecf0 !important;
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2px !important;
  color: #090a0b !important;
  font-weight: 400 !important;
  border: 1px solid !important;
  border-color: #043365 !important;
}

.unselected-chip {
  background-color: #ffffff !important;
  border: 1px solid #cdd6e0 !important;
  color: #090a0b !important;
  font-weight: 400 !important;
  margin-bottom: 2px !important;
}

.frequency-button {
  display: inline-flex;
  font-weight: 500;
  color: #365c84;
  cursor: pointer;
}

.margin-left {
  margin-left: 4rem !important;
}

.field-chip div {
  margin-right: 5px;
}

.rotateIcon {
  transform: rotate(90deg);
}

.rotateIcon > svg {
  vertical-align: baseline;
  margin-left: -5px;
}

.chip-overflow {
  overflow-y: auto;
  overflow-x: hidden;
}

.chip-box {
  max-height: 150px;
}

.frequency-date {
  display: inline-flex;
  margin-top: 20px;
  float: initial;
  left: 65px;
}

.cohort-chip-box {
  max-height: 100px;
}

.helperTextGlobelQuery {
  color: black !important;
}

.action-button button {
  margin-left: 10px;
}

.action-button-blue {
  color: white !important;
  background: #043365 !important;
}

.warning-button {
  color: white !important;
  background: #e4a11b !important;
}

.error-button {
  color: white !important;
  background: #dc4c64 !important;
}

.action-button-approved {
  color: #00875a !important;
  border-color: #00875a !important;
}

.action-button-aborted {
  color: #e4a11b !important;
  border-color: #e4a11b !important;
}

.action-button-rejected {
  color: #dc4c64 !important;
  border-color: #dc4c64 !important;
}

.row-width {
  min-width: 13%;
  max-width: 15%;
  text-align: right;
}

.main-collapse {
  border: none !important;
}

.read-manage-collapse {
  background: #fafbfc !important;
  border-radius: 8px !important;
  box-shadow:
    0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.unread-manage-collapse {
  background: #ebecf0 !important;
  border-radius: 8px !important;
  box-shadow:
    0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

.read-manage-collapse > div > .ant-collapse-content-box,
.unread-manage-collapse > div > .ant-collapse-content-box {
  padding: 16px 30px !important;
}

.feature-box {
  width: 100% !important;
  max-height: 10rem !important;
  overflow-y: auto;
}

.action-sub-text {
  display: flex;
  font-size: 18px;
  margin-left: -3px;
}

.action-header {
  color: #091e42 !important;
}

.title-row {
  display: flex;
}

.cohort-preview-icon {
  margin-left: 5px;
}

.preview-title-info {
  padding: 10px !important;
}

.request-log-title-container {
  display: flex;
}

.request-log-title-container span {
  color: #365c84;
}

.request-log-title-name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #365c84;
}

.request-log-sort-btn > span {
  text-transform: capitalize;
  color: #365c84;
}

.request-filter-popover {
  cursor: pointer;
}

.request-log-sort-btn span > span {
  color: #365c84;
}

.margin-auto {
  margin: auto !important;
}

.row-count {
  margin-bottom: 0px !important;
  align-self: center;
}

.request-log-header-left > span {
  color: #365c84;
}

.tooltip-requests-log-filter {
  border-radius: 10px;
  margin-top: -10px;
  border-radius: 5px;
}

.tooltip-requests-log-filter div {
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #cdd6e0;
}

.tooltip-requests-log-filter div:last-child {
  border-bottom: none !important;
}

.applied {
  background: #cdd6e0;
}

.action-button-cancelled {
  color: #043365 !important;
  border-color: #043365 !important;
}

.admin-action-button-approved {
  color: #00875a !important;
  border-color: #00875a !important;
}

.admin-action-button-cancelled {
  padding: 7px 18px;
  border: 1px solid;
  opacity: 0.5;
  border-radius: 5px;
  border-color: #000000;
}

.text-area-modify textarea {
  font-size: 14px;
  font-weight: 400;
}

.frequency-info-icon {
  align-self: center;
  color: #365c84;
  margin-left: 2px;
}

.downlaod-icon {
  margin-right: 10px !important;
  border: 1px solid #00875a !important;
  border-radius: 5px !important;
}

.downlaod-icon .ant-card-body {
  padding: 0px !important;
  margin: 5px;
  width: 100%;
}

.download-icon-managerequest {
  display: inline-flex;
}

.download-icon-managerequest svg {
  width: 22px;
}

.show-download-icon {
  display: flex;
  width: 84%;
  max-height: 120px;
  overflow-y: auto;
}

.svg-icon {
  display: flow-root;
  width: 25px;
}

.container-row-space {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.container-row-space div {
  margin-bottom: 1px;
}

.text-with-icon {
  align-self: center;
  width: 70px;
  display: block;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

.download-text-color {
  color: #00875a;
}

.no-download-text-color {
  color: #090a0b;
}

.line-width {
  word-break: break-word;
  width: 80%;
}

.job-status-label {
  display: inline-block;
}

.job-status-label > span {
  width: auto;
  height: auto;
}

.job-status-label > span > span {
  font-size: 30px;
  color: #00875a;
}

.job-status-not-started {
  color: #366190 !important;
}

.icon-black-color {
  color: black;
}

/* Cohort ops preview css end */
.metric_btn {
  display: flex;
  min-width: 0;
}

.border-class {
  border-bottom: 2px solid #1976d2 !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.border-class:hover {
  border-bottom: 2px solid #0056b3 !important;
  color: #003087 !important;
  background-color: transparent !important;
}

.disabled-border-class {
  background-color: #69696924 !important;
  width: 110px !important;
  border-radius: 0px;
}

.border-class {
  text-align: initial;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  padding: 20px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.border-class span:nth-child(2) {
  cursor: pointer;
  display: inline-block;
  position: initial;
  transition: 0.5s;
}

/* .border-class span:nth-child(2):after {
  content: '\00bb';
  font-size: 25px;
  position: absolute;
  opacity: 0;
  top: -5px;
  right: -20px;
  transition: 0.5s;
} */

/* .border-class:hover span:nth-child(2) {
  padding-right: 15px;
} */

/* .border-class:hover span:nth-child(2):after {
  opacity: 1;
  right: 5px;
} */

/* Cluster Analyser */
.avl-field-chip {
  border-radius: 15px;
  border: 1px solid var(--primary-blue-20, #cdd6e0);
  background: var(--n-0, #fff);
  padding: 5px 8px 5px 8px;
  align-items: center;
  margin-inline-end: 5px;
}

.avl-field-chip-selected {
  border-radius: 15px;
  border: 1px solid var(--primary-blue-20, #cdd6e0);
  background: var(--n-30, #ebecf0);
  padding: 5px 8px 5px 8px;
  align-items: center;
  margin-inline-end: 5px;
}

.query textarea {
  border-radius: 8px 8px 5px 8px;
  border: 1px solid var(--incedo-blue-20, #cdd6e0);
  background: var(--incedo-blue-2, #fafbfc);
  width: 100%;
  /* padding: 2px; */
}

.query p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
  /* let the text wrap preserving spaces */
  /* width: 400px; */
}

.text-overflow-ellipsis-3 {
  overflow: hidden;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
}

.card-description {
  display: flex;
  flex-wrap: wrap;
  margin: 0 !important;
}

.card-description p {
  margin-left: 0.5rem !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  color: #6c757d !important;
}

.analysis-mode {
  border-radius: 15px !important;
  background: var(--n-20, #f4f5f7) !important;
  margin: 4px 0 8px 0 !important;
  transition: transform 0.3s ease;
}

.analysis-mode-selected {
  /* border: 1px solid var(--incedo-blue-40, #9BADC1); */
  border-radius: 15px !important;
  background: var(--n-20, #caedff) !important;
  margin: 4px 0 8px 0 !important;
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 0px 1px 0px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease;
  transform: scale(1.04);
}

.cohort-card {
  background-color: #f4f5f7;
  width: 300px;
  height: 200px;
  box-shadow:
    0px 0px 3px #cdd6e0,
    0px 2px 8px #cdd6e0;
  border-radius: 8px;
  background-color: white;
  margin: 1rem !important;
}

.cohort-card-active,
.cohort-card-active p,
.cohort-card-active span {
  background: #043365 !important;
  color: white !important;
}

.preview-card {
  background-color: #f4f5f7;
  width: 100%;
  height: 315px;
  box-shadow: (#cdd6e0 0px 0px 3px, #cdd6e0 0px 2px 8px);
  border-radius: 8px;
}

cohort-mode-button {
  padding: 5px;
  background: green;
}

cohort-mode-button:visited {
  background: red;
}

.cohort-card-hover {
  min-height: 100px;
  min-width: 300px;
  position: absolute;
  padding: 5px;
  margin: 0%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  box-shadow:
    0px 0px 3px #cdd6e0,
    0px 2px 8px #cdd6e0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

.round-loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.analysis-in-progress {
  min-height: 100px;
  min-width: 300px;
  position: absolute;
  padding: 5px;
  margin: 0%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  box-shadow:
    0px 0px 3px #cdd6e0,
    0px 2px 8px #cdd6e0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: rgba(54, 92, 132, 0.8);
  z-index: 1;
}

.btn-hover-blue {
  background-color: white;
  border: none;
  color: #043365;
  padding-inline: 18px;
  text-transform: uppercase;
  padding: 0.25rem 1.5rem 0.25rem 1.5rem;
}

.btn-hover-blue:hover {
  background-color: #043365 !important;
  border-radius: 8px;
  color: white;
}

.chart-label {
  display: flex;
  padding: 0px 8px 0px 8px;
  margin-bottom: 0;
  gap: 5px;
  align-items: center;
  border-radius: 15px;
  border: 1px solid var(--primary-blue-20, #cdd6e0);
  background: var(--n-30, #ebecf0);
}

.chart-label-selected {
  display: flex;
  padding: 0px 8px 0px 8px;
  margin-bottom: 0;
  gap: 5px;
  align-items: center;
  border-radius: 15px;
  border: 1.5px solid var(--primary-blue-20, #378ced);
  background: var(--n-30, #bfc9f0);
}

.chart-legend-valid {
  display: flex;
  padding: 0px 8px 0px 8px;
  gap: 5px;
  align-items: center;
  border-radius: 15px;
  border: 1.5px solid var(--primary-blue-20, #de4324);
  background: var(--n-30, #e0aaa7);
}

.legend-container {
  position: relative;
}

.legend-container input {
  padding-left: 30px;
}

.legend-icon {
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
}

.query-popover {
  max-width: 70vw;
  padding: 1.5rem;
  max-height: 40vh;
  overflow: auto;
}

.text-justify {
  text-align: justify !important;
}

.theme-dark.KPIModal .ant-modal-content,
.theme-dark.KPIModal .ant-modal-header {
  background: #242424;
}

.display-linebreak {
  white-space: pre-line;
}
/* .fulltabcontainter{
  background: #d3e3fd !important;  
}
.tabcontainter{
  background: white !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.padding-tab{
  padding: 0px !important;
} */

.variable-table {
  margin-left: 13rem;
  margin-right: 13rem;
}

.wrap-text{
  word-break: break-all;
}