@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
@import 'bootstrap/dist/css/bootstrap.min.css';

@import './styles/theme-variables.scss';

:root {
  --font-primary: 'Roboto', sans-serif;
  --font-secondary: 'Roboto Slab', serif;
}

//color-theme
$bg-light: #fff;
$bg-primary-blue: #043365;
$bg-primary-dark: #121212;
$bg-secondary-dark: #242424;
.text-primary-blue {
  @include themify($themes) {
    color: themed('textPrimary');
  }
}
.text-secondary-blue {
  color: #365c84;
}
.text-primary-blue40 {
  color: #9badc1 !important;
}
.text-primary-default {
  color: #7d7d7e !important;
}
.bg-primary-blue {
  background: $bg-primary-blue !important;
}
.bg-primary-blue40 {
  background: #9badc1 !important;
}
.bg-primary-dark {
  background: $bg-primary-dark !important;
}
.cursor-pointer {
  cursor: pointer;
}
.outline-none {
  outline: none !important;
}
.font-secondary {
  font-family: var(--font-secondary) !important;
}

.text-muted {
  @include themify($themes) {
    color: themed('mutedText') !important;
  }
}

.dark-mode {
  background-color: $bg-primary-blue;
  color: #fff;
}

//Sidebar-menu
$MuiDrawer-width: 220px;
$MuiDrawer-widthCollapsed: 56px;

@mixin webkit($prop, $value) {
  -webkit-#{$prop}: $value;
  #{$prop}: $value;
}

@mixin content-width($width, $radius: 0) {
  width: $size;
  height: $size;

  @if $radius != 0 {
    border-radius: $radius;
  }
}

html,
body {
  font-family: var(--font-primary) !important;

  @include themify($themes) {
    background-color: themed('bodyBackgroundColor');
  }
}

a:hover {
  text-decoration: none;
}

h6 {
  @include themify($themes) {
    color: themed('textColor') !important;
  }
}

.box-shadow-none {
  box-shadow: none !important;
}

.bg-overlay {
  &::after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    left: 0;
    top: 0;
    z-index: -1;
  }
}

.header-area {
  background-color: $bg-light;
  border-bottom: 1px solid #dee2e6;
  min-height: 48px;
  padding-top: 6px;
  z-index: 11;
  .profile-icon {
    background: $bg-primary-blue;
    width: 35px;
    height: 35px;
  }
}
.theme-dark .header-area {
  border-bottom-color: rgba(255, 255, 255, 0.12);
  @extend .dark-mode;
  .profile-icon {
    background: lighten($bg-primary-blue, 10%);
  }
}
.flyout {
  position: relative;
  display: flex;
  height: 100%;
  top: 48px;
}

.MuiDrawer-menu {
  width: $MuiDrawer-width;
  @include webkit(transition, width 50ms cubic-bezier(0.4, 0, 0.6, 1) 20ms);
  .MuiDrawer-paper {
    width: $MuiDrawer-width;
    top: 48px;
    z-index: 10;
    white-space: nowrap;
    overflow: hidden;
    .MuiListItem-root {
      color: inherit;
    }
    .MuiListItem-root.active {
      background: #f1f3f4;
    }
    .MuiListItemIcon-root {
      min-width: 40px;
    }
    .MuiTypography-body1 {
      font-size: 0.9rem;
    }
    .MuiCollapse-root {
      background: #f1f3f4;
      color: #4e4e4e;
      padding: 10px 0;

      .MuiListItemIcon-root {
        min-width: 30px;
      }
    }
    .MuiList-helpGuide {
      background: #fff;
    }
    .expand-icon {
      color: rgba(#000, 50%);
    }
    .subMenuListItem {
      .MuiListItem-root {
        border-left: 2px solid #e8e8e8;
        padding-left: 16px;
        margin-left: 20px;
      }
      .MuiListItem-root.active {
        border-left: 2px solid #0667e9;
        color: #0667e9;
      }
    }
    .subSubMenuListItem {
      .MuiListItem-root {
        border-left: 2px solid #e8e8e8;
        padding-left: 16px;
        margin-left: 20px;
      }
      .MuiListItem-root.active {
        border-left: 2px solid #0667e9;
        color: #0667e9;
      }
    }
  }
}

.MuiDrawer-menu-collapsed {
  width: $MuiDrawer-widthCollapsed;
  .MuiDrawer-paper {
    width: $MuiDrawer-widthCollapsed;
  }
}

main.main-content {
  flex-grow: 1;
  width: calc(100% - 220px);
  min-height: 93vh;

  @include themify($themes) {
    background: themed('bodyBackgroundColor');
  }

  .bg-white {
    @include themify($themes) {
      background-color: themed('bodyBackgroundColor') !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    @include themify($themes) {
      border-color: themed('borderColor');
    }
  }

  .MuiInputBase-root {
    @include themify($themes) {
      color: themed('textColor');
    }
    .material-icons {
      @include themify($themes) {
        color: themed('textColor');
      }
    }
  }

  .MuiSwitch-track {
    @include themify($themes) {
      background-color: themed('textColor');
    }
  }
}

.MuiDrawer-menu-collapsed + main.main-content {
  width: calc(100% - 56px);
}

.MuiInputLabel-root.text-primary-blue {
  @include themify($themes) {
    color: themed('textPrimary') !important;
  }
}

.Mui-typehead {
  .MuiChip-root {
    height: 20px;
    background: none;
    border-radius: 0;
    .MuiChip-label {
      padding: 0;
    }
    .MuiChip-deleteIcon {
      display: none;
    }
  }
  .MuiFormControl-root .MuiInputBase-adornedEnd {
    padding-right: 40px !important;
  }
}

.tree-card {
  width: 344px;
  min-height: 182px;
  border-radius: 8px;

  @include themify($themes) {
    background-color: themed('toggleBackground');
    @include webkit(box-shadow, themed('box-shadow'));
  }

  h6 {
    span,
    span.text-muted {
      @include themify($themes) {
        color: themed('textPrimary') !important;
      }
    }
  }

  span {
    @include themify($themes) {
      color: themed('textPrimary') !important;
    }
  }

  span.text-muted {
    @include themify($themes) {
      color: themed('mutedText') !important;
    }
  }

  span.node-count {
    @include themify($themes) {
      background: themed('lightButton');
      color: themed('textPrimary') !important;
    }
  }
}

.MuiSkeleton-wrapper {
  background: #f8f8f8;
  .node-status {
    background: #d4d4d4;
  }
}

// theme dark style
.theme-dark {
  main.main-content {
    .sub-header {
      .border-bottom,
      .border-top {
        border-color: rgba(255, 255, 255, 0.12) !important;
      }
    }
  }

  .asset--card {
    background-color: $bg-secondary-dark;
  }

  .tree-card {
    border: 1px solid #6b778c;
    border-left: none;
  }

  .MuiSkeleton-wrapper {
    border-color: #545454 !important;
  }
  .MuiSkeleton-wrapper {
    background: $bg-secondary-dark;

    .MuiSkeleton-root {
      background-color: rgba(255, 255, 255, 0.11);
    }
    .node-status {
      background: #545454;
    }
  }

  .MuiDrawer-menu {
    .MuiDrawer-paper {
      @extend .dark-mode;
      .MuiSvgIcon-root,
      .MuiIcon-root,
      .MuiListItemText-root {
        color: lighten($bg-primary-blue, 70%);
      }
      .MuiDivider-root {
        background-color: rgba(255, 255, 255, 0.12);
      }
      .MuiCollapse-root {
        background: lighten($bg-primary-blue, 5%);
      }
      .MuiListItem-root.active {
        background: lighten($bg-primary-blue, 5%);
      }
      .MuiList-helpGuide {
        background: transparent;
      }
      .expand-icon {
        color: rgba(#fff, 50%);
      }
      .subMenuListItem {
        .MuiListItem-root {
          border-left: 2px solid #15559b;
        }
        .MuiListItem-root.active {
          border-left: 2px solid #fff;
          color: #fff;
          span {
            color: #fff;
          }
        }
      }
    }
  }

  .MuiTab-root {
    color: #a0a0a0;
  }

  .MuiTab-root.Mui-selected {
    color: #fff;
  }

  .MuiButton-root.MuiButton-outlined {
    border: 1px solid #a0a0a0;
    font-weight: 400;
    color: #fff;
  }

  .MuiInputBase-root.MuiOutlinedInput-roott,
  .MuiInputLabel-root.MuiInputLabel-formControl,
  .MuiSvgIcon-root.MuiSelect-icon {
    font-weight: 400;
    color: #fff;
  }

  .nodata-placeholder {
    h4 {
      color: #fff;
    }
    p {
      color: #a0a0a0;
    }
  }

  .ant-drawer-content {
    background: $bg-primary-blue;

    .MuiTextField-root.bg-white,
    .MuiAutocomplete-root.bg-white,
    .MuiFormControl-root.bg-white {
      background-color: transparent !important;
      border-color: #fafbfc;
    }

    input,
    textarea,
    .Mui-typehead .MuiChip-root,
    .MuiInputBase-root.MuiOutlinedInput-root,
    .MuiButton-root.MuiButton-text {
      color: #fafbfc;
    }

    fieldset {
      border-color: #fafbfc;
    }

    .MuiButtonBase-root.MuiRadio-root,
    label {
      span {
        color: #a0a0a0 !important;
      }
    }
  }
}

.MuiMenu-root.MuiMenu-root-dark {
  ul.MuiMenu-list {
    background: #36394c;
    color: #eff2f7;
  }
}

// asset card
.asset--card {
  border-radius: 5px !important;
  min-width: 150px;

  @include themify($themes) {
    background: themed('toggleBackground');
  }
}
.asset--card:hover p,
.asset--card:hover span,
.asset--card:hover {
  background: #043365;
  color: white !important;
}
.asset-card-active,
.asset-card-active p,
.asset-card-active span {
  background: #043365 !important;
  color: white !important;
}
.asset-link {
  span {
    @include themify($themes) {
      color: themed('iconColor');
    }
  }
}

.login-card {
  .ant-modal-content {
    @include themify($themes) {
      background: themed('headerFooterBackground');
    }
    p,
    input {
      @include themify($themes) {
        color: themed('textColor');
      }
    }
  }
}

// menu-config
.menu-configuration {
  .ant-collapse-item-active {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 25px;
      width: 1px;
      height: 95%;
      background: #ccc;
      z-index: -1;
    }
  }
  .menu-card-child-wrapper {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 75px;
      width: 1px;
      height: calc(100% - 24px);
      background: #ccc;
      z-index: -1;
    }
  }
  .menu-card-child {
    position: relative;
    overflow: visible;
    &::after {
      content: '';
      position: absolute;
      top: 25px;
      left: -38px;
      width: 38px;
      height: 1px;
      background: #ccc;
    }
  }
  .ant-collapse-content-box > .menu-card-child-wrapper {
    &:last-child {
      &::before {
        content: '';
        position: absolute;
        top: 26px;
        left: 9px;
        width: 1px;
        height: 100%;
        background: #fff;
      }
    }
  }

  .ant-collapse-header {
    padding: 0 !important;
  }
}

//Login page
.login-modal {
  max-width: 900px;
  width: 100%;

  @include webkit(box-shadow, (0 0 10px 1px rgba(0, 0, 0, 0.05)));
  background: #cee1f0;
  border-radius: 4px;
  overflow: hidden;

  .login-content {
    width: 100%;
    max-width: 414px;
    border: 1px solid #cee1f0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.login-icon-btn section {
  padding: 1.2rem 1rem 0rem 1rem;
}

.login-icon-btn button {
  background-color: white;
  width: 3rem;
  height: 3rem;
  border: 1px solid grey;
  border-radius: 15px;
  border-width: 1px;
  border-color: #ddd;
  box-shadow: none;
}

.login-icon-btn label {
  font-size: 0.8rem;
  font-weight: 300;
}

.line {
  text-align: center;
  border-bottom: 1px solid #ccc;
  line-height: 0px;
  margin: 10px auto;
  padding-top: 1rem;
}

.line span {
  background: #fff;
  padding: 0 10px;
}

/* Kpi cards hover effect */
.cardHover {
  top: 0;
  left: 0;
  width: 344px;
  min-height: 182px;
  box-shadow:
    0px 0px 3px #cdd6e0,
    0px 2px 8px #cdd6e0;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
}
.cardHoverText {
  background: #e6ebf0;
  color: #365c84;
  border-radius: 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.align-top-column {
  vertical-align: top;
}

//KPIInsights card
.key-insights-card {
  @include themify($themes) {
    background: themed('toggleBackground');
    color: themed('textColor') !important;
  }
  .MuiButton-root.MuiButton-outlined,
  .MuiButton-root.MuiButton-text {
    @include themify($themes) {
      background: themed('lightButton');
    }
  }
  textarea {
    @include themify($themes) {
      background: themed('bodyBackgroundColor');
    }
  }
}

.textarea {
  @include themify($themes) {
    background: themed('bodyBackgroundColor');
    color: themed('textColor') !important;
  }
}

.ant-dropdown:before {
  position: relative;
}

.filter-selected {
  > .MuiSelect-select {
    > .MuiListItemText-root {
      > .MuiListItemText-secondary {
        color: gray;
        font-size: 12px;
        width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}