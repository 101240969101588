$themes: (
  light: (
    bodyBackgroundColor: #fff,
    headerFooterBackground: #fff,
    textPrimary: #043365,
    textColor: #000,
    iconColor: #7f7f7f,
    toggleBackground: #fff
  ),
  dark: (
    bodyBackgroundColor: #121212,
    headerFooterBackground: #36394c,
    textPrimary: #e1e1e1,
    textColor: #eff2f7,
    iconColor: #fff,
    toggleBackground: #242424,
    mutedText: #a3a3a3,
    lightButton: #043365,
    borderColor: #6b778c
  ),
  default:
    (
      textPrimary: #043365,
      iconColor: #7f7f7f,
      lightButton: #f2f5f7,
      box-shadow: (
        #cdd6e0 0px 0px 3px,
        #cdd6e0 0px 2px 8px
      )
    )
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
